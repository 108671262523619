<template>
  <div class="redeem-wrapper">
    <scroll-list
      :page="page"
      :pageSize="pageSize"
      :total="total"
      :data="data"
      :getData="getData">
      <div class="reward-list-wrapper">
        <div class="reward-item-wrapper" v-for="item in data" :key="item.idCcAward">
          <img :src="item.imageUrl" alt="" v-lazy="item.imageUrl">
          <h4>{{item.name}}</h4>
          <p>{{item.creditAmount}}积分</p>
          <div class="exchange-btn" @click="exchangeAward(item.idCcAward)">兑换</div>
        </div>
      </div>
    </scroll-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api/index'
import { saveLoading } from '@/utils/utils'
const { getConvertibleAwards, exchangeAward } = API

export default {
  name: 'redeem',
  data () {
    return {
      data: [], // 分页数据
      total: -1,
      page: 1,
      pageSize: 10
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    // 获取可兑换奖品列表
    async getData (isRefresh) {
      if (isRefresh) {
        this.page = 1
      }

      let res = await getConvertibleAwards({
        start: this.page,
        limit: this.pageSize
      })

      if (isRefresh || this.total === -1) {
        if (res.success) {
          this.total = res.data.length
        } else {
          throw new Error(res.message)
        }
      }

      this.data = isRefresh ? res.data : this.data.concat(res.data)
      this.page++
    },
    // 兑换奖品
    exchangeAward (awardId) {
      if (this.userInfo.phone) {
        this.$toast.allowMultiple()
        this.$dialog.confirm({
          message: '是否确认兑换？'
        }).then(() => {
          saveLoading({
            data: { awardId },
            api: exchangeAward,
            successMsg: '兑换申请成功！',
            success: () => {
              this.$emit('exchangeSuccess')
            }
          })
        })
      } else {
        this.$toast('请先绑定手机号')
        this.$router.push('/baseInfo/changePhone')
      }
    }
  },
  components: {
    'scroll-list': () => import('@/components/ScrollList.vue')
  }
}
</script>

<style lang="scss" scoped>
  .redeem-wrapper{
    padding-bottom: 24px;
    .reward-list-wrapper{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .reward-item-wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
        img{
          display: block;
          width: 350px;
          height: 350px;
          margin-bottom: 16px;
        }
        h4{
          font-size: 26px;
          color: #333;
          margin-bottom: 8px;
        }
        p{
          font-size: 24px;
          color: #FC5E25;
          font-weight: 600;
          margin-bottom: 16px;
        }
        .exchange-btn{
          width:134px;
          height: 64px;
          font-size: 28px;
          color: #fff;
          background: #0482FF;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 24px;
        }
      }
    }
  }
</style>
